import * as React from "react";
import PageBody from "@/components/PageBody";
import H2 from "@/components/Headings/H2";
import Paragraph from "@/components/Paragraph";
import Centered2x2Grid from "../../components/Centered2x2grid";
import { groupLesson as leadFormFields } from "../../config/ContactForm.data";

import Layout from "../../components/Layout";
import { createSeoData } from "../../utils/page";

const features = [
  {
    name: "Communication",
    description:
      "You are able to communicate with other learners, this can lead to language development as you test out new skills with each other.",
    icon: "SpeakerphoneIcon",
  },
  {
    name: "Shared perspectives",
    description:
      "You are able to see the perspectives of other learners, this can help you change your own perspective and other new insights about the learning process you may have missed on your own.",
    icon: "ChatIcon",
  },
  {
    name: "Emulation",
    description:
      "You can see peers who are excelling in the language learning process and begin to emulate their actions in order to improve your own learning ability.",
    icon: "GlobeAltIcon",
  },
  {
    name: "Pooled resources",
    description:
      "In a group environment, you can pool your knowledge and examples together to create a larger and more concise picture to learn with. You can also share work between each other to help each other advance to the next level.",
    icon: "UserGroupIcon",
  },
];

export default function GroupLessons() {
  const seo = createSeoData({
    title: "Group Language Lessons",
    description:
      "Do you prefer learning with peers? Online group language courses are a great way to get conversational language practice. Sign up for a group language course online today!",
  });

  const hero = {
    heroTitleLine1: "Learn a language with a",
    heroTitleLine2: "Group lesson",
  };

  return (
    <Layout seo={seo} hero={hero} leadFormFields={leadFormFields}>
      <PageBody limitMaxWidth>
        <H2 title="Are you looking to learn with other people in a remote classroom environment?" />
        <Paragraph>
          Well, a group lesson is for you! Group learning has the advantage of
          interacting with other learners, which is a great way to improve your
          language skills as you try new techniques taught by our tutors with
          each other. Furthermore, you can learn from each other throughout the
          lesson as questions you didn’t think about could be asked and further
          your own language skills.
        </Paragraph>
      </PageBody>
      <PageBody>
        <Centered2x2Grid
          title="What can you gain from a Group lesson?"
          subtitle="A better way to learn a language"
          features={features}
        />
      </PageBody>
      <PageBody limitMaxWidth>
        <Paragraph>
          To conclude, group lessons are a valuable tool for a more sociable
          learning experience than in a private lesson structure. You are able
          to learn with other people who will be at a similar level of knowledge
          as you, as well as learning with them… any valuable learning
          techniques and from their inputs in the classes. Group lessons provide
          you with a social environment that is great for learning speaking
          aspects of any language...
        </Paragraph>
        <Paragraph>
          However, if you think that more 1-on-1 time with the tutor is a better
          option for you… then make sure to check out our{" "}
          <a
            href="/lessons/private"
            className="underline font-medium text-indigo-600 hover:text-indigo-500"
          >
            private lessons
          </a>
          !
        </Paragraph>
      </PageBody>
    </Layout>
  );
}
